<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>数据统计</span>
        <div>
          <!-- <a :href="'/conn-portal/wapi/v1/dingbian/export/total/statistics?date='+(dateSelect=='single_day'?date:new Date(new Date().toLocaleDateString()).getTime())+'&dateType='+dateSelect" target="_blank">
            <el-button class="btn" style="margin-right: 10px" type="primary" size="small">导出报表</el-button>
          </a> -->
          <el-button class="btn" style="margin-right: 10px" type="primary" size="small" @click="onSubmit">导出报表</el-button>
        </div>
      </div>
      <div style="display:flex;justify-content:space-between;align-items: flex-end;margin-bottom:10px;">
        <div class="total-statistics">
          <div class="total-title">
            <span class="title-left">总正累计流量 (m³)</span>
            <span class="title-right">
              {{ dateSelect=='single_day' ? '单日'
              : dateSelect=='last_seven_day' ? '近7日'
              : dateSelect=='last_fifteen_day' ? '近15日'
              : dateSelect=='month' ? '月'
              : dateSelect=='quarter' ? '季'
              : dateSelect=='year' ? '年'
              : '-' }}
            </span>
          </div>
          <div class="total-num">
            <NumberScroll :time="0.3" :value="positiveCumulativeFlowTotal"></NumberScroll>
          </div>
        </div>
        <div class="search" v-if="roleId != 4">
          <div style="margin-right: 20px;">
            <div v-show="dateSelect=='single_day'">
              <span class="select-label">选择日期:</span>
              <el-date-picker v-model="date" type="date" placeholder="选择日期" :clearable="false" value-format="timestamp" :picker-options="pickerOptions" @change="loadListData"></el-date-picker>
            </div>
            <div v-show="dateSelect=='month'">
              <span class="select-label">选择月份:</span>
              <el-date-picker v-model="month" type="month" placeholder="选择月份" :clearable="false" value-format="yyyy-MM" @change="loadListData"></el-date-picker>
            </div>
            <div v-show="dateSelect=='quarter'">
              <span class="select-label">选择季度:</span>
              <ElQuarterPicker v-model="quarter" placeholder="选择季度" :clearable="false" value-format="yyyy-q" @change="loadListData"></ElQuarterPicker>
            </div>
            <div v-show="dateSelect=='year'">
              <span class="select-label">选择年份:</span>
              <el-date-picker v-model="year" type="year" placeholder="选择年份" :clearable="false" value-format="yyyy" @change="loadListData"></el-date-picker>
            </div>
          </div>
          <div>
            <el-radio-group v-model="dateSelect" size="small" @change="dateChange">
              <el-radio-button label="single_day">单日</el-radio-button>
              <el-radio-button label="last_seven_day">近7日</el-radio-button>
              <el-radio-button label="last_fifteen_day">近15日</el-radio-button>
              <el-radio-button label="month">月</el-radio-button>
              <el-radio-button label="quarter">季</el-radio-button>
              <el-radio-button label="year">年</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%" size="small">
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column label="名称">
              <template slot-scope="scope">
                <span>{{ scope.row.position?scope.row.position+'('+scope.row.waterworks+')':'-' }}</span>
              </template>
          </el-table-column>
          <el-table-column prop="singleTotalPositiveFlow" label="正累积流量（m³）">
              <template slot-scope="scope">
                {{ scope.row.singleTotalPositiveFlow?scope.row.singleTotalPositiveFlow:0 }}
              </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
  
<script>
import page from '@/components/page.vue'
import dayjs from "dayjs";
import NumberScroll from '@/components/NumberScroll.vue'
import ElQuarterPicker from '@/components/ElQuarterPicker.vue';
export default {
  components: {
    page,
    NumberScroll,
    ElQuarterPicker
  },
  data() {
    return {
      loading: false,
      date: null,
      month: null,
      quarter: null,
      year: null,
      tableData: [],
      dateSelect: 'single_day',
      roleId: 0,
      positiveCumulativeFlowTotal: 0,
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d > n;
        }
      },
    };
  },
  methods: {
    dayjs,
    // 导出报表
    onSubmit() {
      this.loading = true;
      this.$ajax.exportRecord("dingbianExport", {
        date: this.dateSelect=='single_day'?this.date:new Date(new Date().toLocaleDateString()).getTime(),
        month: this.month?this.month:null,
        quarter: this.quarter?this.quarterHanlder():null,
        year: this.year?this.year:null,
        dateType: this.dateSelect,
      }).then((res) => {
        const blob = new Blob([res])
        let custFileName
        if(this.dateSelect=='single_day') custFileName = this.timeStFormat(this.date)
        if(this.dateSelect=='last_seven_day') custFileName = '近7日'
        if(this.dateSelect=='last_fifteen_day') custFileName = '近15日'
        if(this.dateSelect=='month') custFileName = this.month
        if(this.dateSelect=='quarter') custFileName = this.quarterHanlder()
        if(this.dateSelect=='year') custFileName = this.year+'年'
        const fileName = custFileName + '统计报表.xls';
        const linkNode = document.createElement('a');
        linkNode.download = fileName;
        linkNode.style.display = 'none';
        linkNode.href = URL.createObjectURL(blob);
        document.body.appendChild(linkNode);
        linkNode.click();
        URL.revokeObjectURL(linkNode.href);
        document.body.removeChild(linkNode);
        this.loading = false;
        this.$message.success('导出成功')
      }).catch(err=>{
        this.loading = false
        this.$message.error('导出失败')
      })
    },
    // 月季年筛选事件
    dateChange(e) {
      if(e=='single_day') { this.month = null, this.quarter = null, this.year = null, this.date = this.getTimestamp(), this.loadListData() }
      if(e=='last_seven_day') { this.month = null, this.quarter = null, this.year = null, this.loadListData() }
      if(e=='last_fifteen_day') { this.month = null, this.quarter = null, this.year = null, this.loadListData() }
      if(e=='month') { this.quarter = null, this.year = null, this.month = this.getMonth(), this.loadListData() }
      if(e=='quarter') { this.month = null, this.year = null, this.quarter = this.getQuarter(), this.loadListData() }
      if(e=='year') { this.month = null, this.quarter = null, this.year = this.getYear(), this.loadListData() }
    },
    // 获取0点时间戳
    getTimestamp() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let date = yDate.getDate();
      let day = year+'-'+month+'-'+date
      let time = (new Date(new Date(day).toLocaleDateString())).getTime();
      return time
    },
    // 时间戳转日期格式
    timeStFormat(timestamp) {
      let yDate = new Date(timestamp)
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let date = yDate.getDate();
      month = month < 10 ? '0' + month : month;
      date = date < 10 ? '0' + date : date;
      return year+'-'+month+'-'+date
    },
    // 季度格式提交参数处理
    quarterHanlder() {
      let comp = this.quarter
      let sp = comp.split('-')
      let format = sp[0]+'-'+'Q'+sp[1]
      return format
    },
    // 月份格式初始化
    getMonth() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      month = month < 10 ? '0' + month : month;
      return year+'-'+month
    },
    // 季度格式初始化
    getQuarter() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let quarter = ''
      if(month<=3) {
        quarter = 1
      }else if(month>3 && month<=6) {
        quarter = 2
      }else if(month>6 && month<=9) {
        quarter = 3
      }else if(month>9 && month<=12) {
        quarter = 4
      }
      return year+'-'+quarter
    },
    // 年份格式初始化
    getYear() {
      let yDate = new Date()
      return (yDate.getFullYear()).toString()
    },
    // 获取列表数据
    loadListData() {
      this.loading = true
      this.$ajax.post('dingbianStatTotaoQuery', {
        date: this.dateSelect=='single_day'?this.date:null,//指定日期,默认0点时间戳
        month: this.month?this.month:null,
        quarter: this.quarter?this.quarterHanlder():null,
        year: this.year?this.year:null,
        dateType: this.dateSelect,
      }).then(res => {
        this.tableData = res.data
        let total = 0
        res.data.forEach((i)=>{total+=parseFloat(i.singleTotalPositiveFlow)})
        this.positiveCumulativeFlowTotal = parseFloat(total.toFixed(2))
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.date = this.getTimestamp()
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  }
};
</script>
  
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
/deep/.export-dialog .el-dialog {
  margin-top: 10vh !important;
  background: #f0f9eb;
  border-radius: 5px;
  box-shadow: 0px 0px 0px;
}
/deep/.export-dialog .el-dialog__header {
  padding: 20px 20px 0px;
}
/deep/.export-dialog .el-dialog__title {
  font-size: 16px;
  color: #67c23a;
}
/deep/.export-dialog .el-dialog__body {
  padding: 10px 20px;
  color: #67c23a;
}
.man-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-label {
    font-size: 14px;
    margin-right: 10px;
    color: #767779;
  }
}
.total-statistics {
  background-color: #F3F7FE;
  border-radius: 10px;
  font-size: 14px;
  color: #797979;
  padding: 5px 20px;
  margin-right: 10px;
  .total-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .title-left {
      width: 170px;
      font-size: 14px;
    }
    .title-right {
      width: 45px;
      font-size: 14px;
      color: #8F8F8F;
      font-weight: 100;
      background-color: #FFB145;
      color: #FFF;
      border-radius: 4px;
      padding: 0 5px;
      text-align: center;
    }
  }
  .total-num {
    font-size: 36px;
    color: #202020;
    font-weight: bold;
  }
}
/deep/.el-form-item {
  margin-bottom: 0px;
}
/deep/.el-radio-group {
  background-color: #F5F5F5;
  padding: 5px 0;
  border-radius: 4px;
}
/deep/.el-radio-button__inner {
  border: 0;
  background: none;
  border-radius: 4px;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
  user-select: none;
}
/deep/.el-radio-button__inner:hover {
  background: #E7E7E7;
  color: #409EFF;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner:hover {
  background: #409EFF;
  color: #FFF;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border: 0;
  border-radius: 4px;
}
/deep/.el-radio-button:last-child .el-radio-button__inner {
  border: 0;
  border-radius: 4px;
}
/deep/.el-table {
  background-color: #FAFAFA;
  margin: 10px 0;
}
/deep/.el-table__header {
  width: 100% !important;
}
/deep/.el-table__body {
  width: 100% !important;
}
/deep/.el-table::before {
  height: 0;
}
/deep/.el-table__header th {
  color: #767779;
  font-weight: normal;
}
/deep/.el-table th:nth-child(1) {
  border-top-left-radius: 10px;
}
/deep/.el-table th:nth-last-child(2) {
  border-top-right-radius: 10px;
}
/deep/.el-table tbody tr:hover > td {
  background-color: #f5f9ff !important;
}
</style>